@font-face {
font-family: '__NexaBlack_e9e825';
src: url(/_next/static/media/212c0c0077b7c84d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
}@font-face {font-family: '__NexaBlack_Fallback_e9e825';src: local("Arial");ascent-override: 88.29%;descent-override: 28.88%;line-gap-override: 0.00%;size-adjust: 112.26%
}.__className_e9e825 {font-family: '__NexaBlack_e9e825', '__NexaBlack_Fallback_e9e825';font-weight: normal
}.__variable_e9e825 {--font-nexa-black: '__NexaBlack_e9e825', '__NexaBlack_Fallback_e9e825'
}

